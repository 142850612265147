import { Form, submit, useDispatch } from 'dataformjs';
import { navigate, PageProps } from 'gatsby';
import compact from 'lodash/compact';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import IconDelete from '../../../../components/icons/Delete';
import IconSave from '../../../../components/icons/Save';
import AsideBody from '../../../../components/Space/Aside/Body';
import vehicleData from '../../../../objects/vehicles/datas.json';
import {
  vehiclesDelete as deleteMutation,
  vehiclesUpdate as updateMutation,
} from '../../../../objects/vehicles/mutations';
import vehicleQuery from '../../../../objects/vehicles/queries/vehicle.graphql';
import VehicleType from '../../../../types/Vehicle';
import requiredAuth from '../../../../utils/requireAuth';

const VehiclePage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'vehicle';
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [deleteMutationFunc] = useMutation(deleteMutation);
  const [updateMutationFunc] = useMutation(updateMutation);
  const { data, loading, refetch } = useQuery(vehicleQuery, {
    variables: {
      id,
    },
  });

  const vehicle: VehicleType = data && (data.vehicle as VehicleType);
  if (!vehicle) {
    return <CircularProgress fullPage />;
  }

  const handleDeleteOnClick = async () => {
    const ok = window.confirm(
      'Etes-vous sûr de vouloir supprimer ce véhicule ?',
    );

    if (ok) {
      const result = await deleteMutationFunc({
        variables: {
          id,
        },
      });

      if (!result || result.errors) {
        console.error(result.errors);
      } else {
        window.history.back();
      }
    }
  };

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    const {
      arrivalPlace,
      coefHour,
      coefKm,
      coefPec,
      coefVolume,
      coefWeight,
      departurePlace,
      hasHatch,
      hasPalletTruck,
      isActive,
      isRefrigerated,
      maxDistance,
      maxDuration,
      name,
      supDistance,
      supDuration,
      volume,
      weight,
      user,
    } = values;
    await toast.promise(
      updateMutationFunc({
        variables: {
          id,
          data: {
            coefHour,
            coefKm,
            coefPec,
            coefVolume,
            coefWeight,
            maxDistance,
            maxDuration,
            name,
            supDistance,
            supDuration,
            volume,
            weight,
            arrivalPlaceId: arrivalPlace?.id,
            departurePlaceId: departurePlace?.id,
            hasHatch: Boolean(hasHatch),
            hasPalletTruck: Boolean(hasPalletTruck),
            isActive: Boolean(isActive),
            isRefrigerated: Boolean(isRefrigerated),
            userId: user?.id,
          },
        },
      }),
      {
        error: 'Erreur lors de la mise à jour du véhicule',
        pending: 'Mise à jour du véhicule en cours',
        success: 'Mise à jour du véhicule réussie',
      },
    );

    refetch();
  };

  return (
    <GridSplit
      aside={{
        body: (
          <AsideBody
            places={compact([vehicle.departurePlace, vehicle.arrivalPlace])}
          />
        ),
        className: 'sticky top-0 self-start',
        width: '50%',
      }}
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              iconLeft={IconSave}
              onClick={handleSaveOnClick}
              size="small"
              title={t('vehicle.actions.update')}
            />
            <Button
              iconLeft={IconDelete}
              onClick={handleDeleteOnClick}
              size="small"
              status="danger"
              title={t('vehicle.actions.delete')}
            />
          </div>
        ),
        back: {
          onClick: () => {
            navigate(-1);
          },
          text: t(`back`),
        },
        title: vehicle?.name ?? 'Véhicule',
      }}
    >
      {loading && <CircularProgress />}
      <Form
        className="mx-6 mb-6"
        datas={vehicleData}
        hideSubmitButton
        initialValues={vehicle}
        name={formName}
        onSubmit={handleOnSubmit}
        params={{
          spaceId: vehicle.spaceId,
        }}
        submitProps={{
          label: t(`vehicle.form.submit.label`),
          size: 'small',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(VehiclePage, {
  admin: true,
  layout: true,
});
